import React from "react";
import WhatLogo from "assets/images/whatsapp_english.svg";
import { Link } from "gatsby";
export default function WhatsAppComp({ fHead, sHead, content, secondHead, btn, fHeadCol, backgroundCol, btnCol, btnLink, btnHoverCol, btnText, whatsUp, thirdHead }) {
  return (
    <div className={`${backgroundCol ? backgroundCol : "bg-darkBlue"}  flex items-center justify-center`}>
      <div className="main text-white text-center flex items-center flex-col justify-center lg:w-9/12 w-full px-4 md:px-0 lg:py-32 py-20 max-w-screen-2xl">
        <h2 className="text-[35px] font-extrabold font-secondary">
          {" "}
          <span className={`${fHeadCol ? fHeadCol : "text-lightBlue"} `}>{fHead ? fHead : "Professional"} </span>
          {secondHead ? secondHead : "Localization Services at the Tip of Your Fingers"}
          <br />
          <span>{thirdHead}</span>
        </h2>
        {content ? (
          <p className=" pt-8 pb-12 text-darkMist font-opensans leading-8 px-4 md:px-20 lg:w-10/12">{content}</p>
        ) : (
          <p className="font-light pt-16 pb-12 text-darkMist font-opensans leading-8 px-4 md:px-20">
            Speak to the Tomedes team today for more information on the advantages of localization and what it can do for your company. Contact us for more information or you can chat with us via
            Whatsapp.
          </p>
        )}

        {whatsUp ? (
          <div className="div">
            <a href="https://api.whatsapp.com/send?phone=972722200700" target="_blank">
              <img src={WhatLogo} alt="" className="w-[320px]" />
            </a>
          </div>
        ) : (
          " "
        )}

        {btn ? (
          <Link to={btnLink ? btnLink : "/quote"} className={`${btnCol ? btnCol : "bg-lightBlue"}  ${btnHoverCol ? btnHoverCol : "hover:bg-newBlue-100"}  rounded-full   px-9 py-3 `}>
            {btnText ? btnText : "TRANSLATE NOW"}
          </Link>
        ) : null}
      </div>
    </div>
  );
}
